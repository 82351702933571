<script>
import ImageUploadProfile from '@/components/general/ImageUploadProfile'
import UserAvatarInitials from '@/components/general/UserAvatarInitials'
export default {
  name: 'Welcome',
  components: {
    ImageUploadProfile,
    UserAvatarInitials
  },
  data () {
    return {
      actualStep: 0,
      isImageLoading: false,
      isImageSelected: false,
      selectedImage: null,
      mutableValue: ''
    }
  },
  computed: {
    isInvite () {
      return this.$route.query.isInvite === 'true' || this.$route.query.isInvite === true
    },
    getUser () {
      return this.$store.getters.getUser
    },
    setTheme () {
      return this.getTheme(this.$route.query)
    }
  },
  methods: {
    handleSkipImport () {
      this.$emit('progressChange', { stepProgress: 1 })
      this.$router.push({ name: 'newaccount.home.firstAccess.profile', query: { ...this.$route.query }, params: { imported: false, createResume: true } })
    },
    handleSkipToEnd () {
      this.$emit('progressChange', { stepProgress: 3 })
      this.$router.push({ name: 'newaccount.home.firstAccess.categories', query: { ...this.$route.query } })
    },
    handleNextStep () {
      this.$router.push({ name: 'newaccount.home.firstAccess.profile', query: { ...this.$route.query }, params: { imported: true } })
    },
    handleCheckStep (step) {
      this.actualStep = step
    },
    chooseFiles () {
      document.getElementById('fileUpload').click()
    },
    onImageUpload (value) {
      this.$store.dispatch('attemptUpdateUserGlobalInfo', { avatar: value })
      this.$store.dispatch('attemptUpdateIndividualWorkspace', { avatar: value })
      this.mutableValue = value
      setTimeout(() => {
        !!this.isInvite && this.handleSkipToEnd()
      }, 100)
    },
    onImageLoading (value) {
      this.isImageLoading = value
    }
  },
  watch: {
    selectedImage (value) {
      if (value !== null) this.isImageSelected = true
    },
    formData (formData) {
      this.$emit('dataImported', formData)
      this.$emit('progressChange', { stepProgress: 1 })
      this.$router.push({ name: 'newaccount.home.firstAccess.profile', query: { ...this.$route.query } })
    }
  },
  created () {
    this.$emit('progressChange', { stepProgress: 0 })
    this.mutableValue = this.getUser.avatar
    this.$store.dispatch('attemptMoveWizardSteps', { active: true, completed: false, theme: this.$route.query })
  }
}
</script>
<template>
<section class="welcome-selection--container">
  <v-container class="welcome-main--container">
    <v-row>
      <v-col class="welcome-image d-none d-md-flex" xs="12" sm="12" md="6">
        <v-img contain src="/assets/images/registration-rocket.png" />
      </v-col>
      <v-col class="welcome-body pa-0" align-self="center" xs="12" sm="12" md="6">
        <v-card flat class="card-contante">
          <h4 class="mb-4">{{ $t('first.access:title') }}</h4>
          <h5 class="mb-1 mt-8 mb-4">{{ $t('settings:profile.subtitle.profile.picture') }}</h5>
          <div class="card-header">
            <div class="avatar mr-4">
              <v-avatar class="round" size="100" v-if="!!mutableValue">
                <v-img v-if="!!mutableValue && !(mutableValue.includes('/profile-avatar.jpg') || mutableValue.includes('/empty'))" :src="mutableValue" height="100px" width="100px"></v-img>
                <user-avatar-initials v-else :user="workspace" />
              </v-avatar>
              <v-avatar class="round" size="100" v-else></v-avatar>
              <v-progress-circular v-if="isImageLoading" indeterminate color="primary"></v-progress-circular>
            </div>
            <div class="avatar-upload">
              <image-upload-profile
                :vWidth="200"
                :vHeight="40"
                :width="500"
                :height="500"
                :aspectRatio="1"
                :outlined="true"
                :disabled="actualStep === 1"
                :themeColor="setTheme.primaryColor"
                :textButton="$t('first.access.welcome:add.image')"
                :icon="'mdi-upload'"
                @upload-image="onImageUpload"
                @image-loading="onImageLoading"
                ref="imageUpload"
              />
              <p class="subtitle mt-2 mb-0">{{ $t('first.access.welcome:subtitle.image') }}</p>
            </div>
          </div>
          <div class="card-body" v-if="!isInvite">
            <h5 class="mb-1 mt-8">{{ $t('first.access:horizontal.steps.1') }}</h5>
            <p class="subtitle">{{ $t(`${$route.query.company === 'company' ? 'first.access.welcome:subtitle.alternative' : 'first.access.welcome:subtitle'}`) }}</p>
            <v-card flat outlined class="import-card mt-3">
              <div class="import-resume-card">
                <p><v-icon class="mr-1">mdi-check</v-icon>{{ $t('import.resume.modal:import.instruction.1') }}</p>
                <p><v-icon class="mr-1">mdi-check</v-icon>{{ $t('import.resume.modal:import.instruction.2') }}</p>
                <p><v-icon class="mr-1">mdi-check</v-icon>{{ $t('import.resume.modal:import.instruction.3') }}</p>
                <div class="import-resume--bottom-actions mt-4">
                  <v-btn block class="btn transform-unset" :color="setTheme.primaryColor" dark @click="handleNextStep()">
                    <v-icon class="mr-1" dense color="#fff">mdi-file-upload-outline</v-icon>
                    {{ $t('global:select.file') }}
                  </v-btn>
                </div>
              </div>
            </v-card>
          </div>
          <v-card-actions class="card-footer">
            <template v-if="!isInvite">
              <v-btn block outlined class="btn bold transform-unset mb-2 mt-2" :disabled="actualStep === 1" :dark="actualStep !== 1" :color="setTheme.primaryColor" @click="handleSkipImport">{{ $t('first.access.welcome:button.create') }}</v-btn>
              <v-btn block text class="btn bold transform-unset ma-0" :disabled="actualStep === 1" :dark="actualStep !== 1" :color="setTheme.primaryColor" @click="handleSkipToEnd">{{ $t('first.access.welcome:button.skip') }}</v-btn>
            </template>
            <v-btn v-else outlined block class="btn bold transform-unset mt-8" :disabled="actualStep === 1" :dark="actualStep !== 1" :color="setTheme.primaryColor" @click="handleSkipToEnd">{{ $t('first.access.welcome:button.skip.profile.image') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <footer class="welcome--footer">
      <p class="ma-0">Copyright © 2023 Plooral. {{ $t('global:copyright') }}</p>
    </footer>
  </v-container>
</section>
</template>
<style lang="scss">
.welcome-selection--container {
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  .welcome-main--container {
    display: flex;
    flex-direction: column;
    max-width: 1100px;
    padding-top: 0px;
    .row{
      border: 1px solid rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      align-items: center;
      .welcome-body {
        text-align: start;
        .card-contante {
          display: flex;
          flex-direction: column;
          padding: 40px 25px;
          .card-header{
            display: flex;
            align-items: center;
            .avatar {
              .v-progress-circular {
                position: absolute;
                top: 225px;
                left: 60px;
              }
            }
            .all-container {
              display: flex;
              justify-content: flex-start;
              .v-btn {
                margin: 0;
              }
            }
          }
          .import-card{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 24px;
            background: #FAFBFC;
            border: 1px solid rgba(0, 0, 0, 0.06);
            border-radius: 4px;
            p {
              margin-bottom: 4px;
              i {
                font-size: 16px;
              }
            }
          }
          .card-footer {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0;
          }
        }
      }
    }
    .welcome--footer {
        margin-top: 40px;
    }
  }
}
</style>
